@use 'src/styles/vars';

.mat-mdc-progress-bar {
  &.mat-info {
    --mdc-linear-progress-active-indicator-color: #{vars.$color-blue-300};
    --mdc-linear-progress-track-color: #{lighten(vars.$color-blue-primary, 25%)};
  }

  &.mat-success {
    --mdc-linear-progress-active-indicator-color: #{vars.$color-green-500};
    --mdc-linear-progress-track-color: #{lighten(vars.$color-green-500, 25%)};
  }

  &.mat-warning {
    --mdc-linear-progress-active-indicator-color: #{vars.$color-yellow-500};
    --mdc-linear-progress-track-color: #{lighten(vars.$color-yellow-500, 25%)};
  }

  &.mat-danger {
    --mdc-linear-progress-active-indicator-color: #{vars.$color-red-500};
    --mdc-linear-progress-track-color: #{lighten(vars.$color-red-500, 25%)};
  }

  &.competence-result-progress-bar {
    border-radius: 1rem;
    --mdc-linear-progress-track-height: 1rem;
    --mdc-linear-progress-active-indicator-height: 1rem;
    &.crpg-excellent {
      --mdc-linear-progress-active-indicator-color: #{vars.$color-violet-500};
      --mdc-linear-progress-track-color: #{lighten(vars.$color-violet-500, 25%)};
    }
    &.crpg-outstanding {
      --mdc-linear-progress-active-indicator-color: #{vars.$color-green-apple-500};
      --mdc-linear-progress-track-color: #{lighten(vars.$color-green-apple-500, 25%)};
    }
    &.crpg-meets_expectations {
      --mdc-linear-progress-active-indicator-color: #{vars.$color-blue-sky-500};
      --mdc-linear-progress-track-color: #{lighten(vars.$color-blue-sky-500, 25%)};
    }
    &.crpg-below_expectations {
      --mdc-linear-progress-active-indicator-color: #{vars.$color-orange-500};
      --mdc-linear-progress-track-color: #{lighten(vars.$color-orange-500, 25%)};
    }
    &.crpg-unsatisfactoryt {
      --mdc-linear-progress-active-indicator-color: #{vars.$color-red-400};
      --mdc-linear-progress-track-color: #{lighten(vars.$color-red-400, 25%)};
    }
    &.crpg-no_result {
      --mdc-linear-progress-active-indicator-color: #{vars.$color-gray-500};
      --mdc-linear-progress-track-color: #{lighten(vars.$color-gray-500, 25%)};
    }
  }
}
