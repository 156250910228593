@use 'src/styles/vars';

@keyframes dots {
  0%,
  20% {
    color: transparent;
    text-shadow:
      0.25em 0 0 transparent,
      0.5em 0 0 transparent;
  }
  40% {
    color: vars.$color-black;
    text-shadow:
      0.25em 0 0 transparent,
      0.5em 0 0 transparent;
  }
  60% {
    text-shadow:
      0.25em 0 0 vars.$color-black,
      0.5em 0 0 transparent;
  }
  80%,
  100% {
    text-shadow:
      0.25em 0 0 vars.$color-black,
      0.5em 0 0 vars.$color-black;
  }
}

@keyframes borderAnimation {
  0% {
    border-right-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: vars.$color-green-500;
  }
  25% {
    border-right-color: vars.$color-green-500;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  50% {
    border-right-color: transparent;
    border-top-color: vars.$color-green-500;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  75% {
    border-right-color: transparent;
    border-top-color: transparent;
    border-left-color: vars.$color-green-500;
    border-bottom-color: transparent;
  }
  100% {
    border-right-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: vars.$color-green-500;
  }
}

@keyframes borderAnimationInverse {
  0% {
    border-right-color: transparent;
    border-top-color: vars.$color-green-500;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  25% {
    border-right-color: transparent;
    border-top-color: transparent;
    border-left-color: vars.$color-green-500;
    border-bottom-color: transparent;
  }
  50% {
    border-right-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: vars.$color-green-500;
  }
  75% {
    border-right-color: vars.$color-green-500;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  100% {
    border-right-color: transparent;
    border-top-color: vars.$color-green-500;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
}

@keyframes flip-vertical-fwd {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
