@use 'src/styles/vars';

.card-soft {
  app-base-card.soft-shadow {
    background-color: vars.$color-white !important;
    border-radius: vars.$border-radius-standard !important;
  }
}

.base-card-soft .dashboard-base {
  background-color: vars.$color-white !important;
  border-radius: vars.$border-radius-standard !important;
  box-shadow: vars.$shadow-standard !important;
  height: 100%;
  min-width: 250px;
}

mat-card {
  border-radius: vars.$border-radius-standard !important;
  box-shadow: vars.$shadow-standard !important;

  p.card-paragraph {
    font-size: 0.875rem;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.mat-card {
  &-border {
    border: vars.$border-standard !important;
  }
}

.pad-card-total {
  padding: 0.2rem;
}

@media screen and (max-width: vars.$md) {
  .card-config a {
    font-size: 0.875rem;
  }
}
