.mat-mdc-chip,
.mat-chip {
  &.mat-danger {
    background-color: vars.$color-red-500 !important;

    .mat-mdc-chip-action-label {
      color: vars.$color-white !important;
    }
  }

  &.mat-success {
    background-color: vars.$color-green-500 !important;

    .mat-mdc-chip-action-label {
      color: vars.$color-white !important;
    }
  }

  &.mat-warn,
  &.mat-warning {
    background-color: vars.$color-yellow-500 !important;

    .mat-mdc-chip-action-label {
      color: vars.$color-black !important;
    }
  }

  &.mat-info {
    background-color: vars.$color-blue-300 !important;

    .mat-mdc-chip-action-label {
      color: vars.$color-black !important;
    }
  }
}

.mat-icon {
  &.mat-success {
    color: vars.$color-green-500 !important;
  }
  &.mat-danger {
    color: vars.$color-red-500 !important;
  }
}

.mat-badge-danger .mat-badge-content {
  background-color: vars.$color-red-500 !important;
  color: vars.$color-white !important;
}

.mat-badge-accent .mat-badge-content {
  background-color: vars.$color-gray-300 !important;
  color: vars.$color-black !important;
}

.mat-warning {
  background-color: vars.$color-yellow-500 !important;
  color: vars.$color-black !important;
}

.mat-light {
  background-color: vars.$color-gray-100 !important;
  color: vars.$color-black !important;
}

.red-icon {
  color: vars.$color-red-500;
}

.yellow-icon {
  color: vars.$color-yellow-500;
}

.blue-icon,
.color-primary {
  color: vars.$color-blue-primary;
}

.color-blue-light {
  color: vars.$color-blue-300;
}

.color-blue-800 {
  color: vars.$color-blue-800;
}

.gray-background {
  background-color: vars.$color-gray-100 !important;
}

.gray-light-background {
  background-color: vars.$color-gray-300 !important;
}

.color-gray {
  color: vars.$color-gray-600 !important;
}

.color-gray-light {
  color: vars.$color-gray-500 !important;
}

.color-white {
  color: vars.$color-white !important;
}

.background-black {
  background-color: vars.$color-black !important;
}

.background-white {
  background-color: vars.$color-white !important;
}

.background-inherit {
  background-color: inherit !important;
}

.gray-text-muted {
  color: vars.$color-gray-600 !important;
}

.violet-color {
  color: vars.$color-violet-700;
}

.primary-color {
  color: vars.$color-blue-primary !important;
}

.primary-color-background {
  background-color: vars.$color-blue-primary !important;
}

.primary-color-border {
  border-color: vars.$color-blue-primary !important;
}

.gray-color-border {
  border-color: vars.$color-gray-600 !important;
}

.lightviolet-color-border {
  border-color: vars.$color-violet-300 !important;
}

.yellow-color-border {
  border-color: vars.$color-yellow-500 !important;
}

.orange-color-border {
  border-color: vars.$color-orange-500 !important;
}

.green-color-border {
  border-color: vars.$color-green-500 !important;
}

.red-color-border {
  border-color: vars.$color-red-500 !important;
}

.secondary-color {
  color: vars.$color-gray-500 !important;
}

.secondary-color-background {
  background-color: vars.$color-gray-500 !important;
}

.yellow-color-background {
  background-color: vars.$color-yellow-500 !important;
}

.lightviolet-color-background {
  background-color: vars.$color-violet-300 !important;
}

.orange-color-background {
  background-color: vars.$color-orange-500 !important;
}

.danger-color-background {
  background-color: vars.$color-red-500 !important;
}

.color-warn {
  color: vars.$color-orange-500;
}

.color-danger {
  color: vars.$color-red-500 !important;
}

.color-green {
  color: vars.$color-green-500 !important;
}

.color-yellow {
  color: vars.$color-yellow-500 !important;
}

.color-secondary-medium {
  color: vars.$color-gray-200;
}

.color-secondary-background {
  color: vars.$color-gray-200;
}

.color-warn-background {
  background-color: vars.$color-orange-500;
}

.color-warn-light {
  color: vars.$color-orange-100;
}

.color-warn-light-background {
  background-color: vars.$color-orange-100;
}

.color-warn-medium-background {
  background-color: vars.$color-orange-200;
}

.color-warn-light-background-hover {
  background-color: vars.$color-orange-100;
}

.color-warn-light-background-hover:hover {
  background-color: vars.$color-orange-500;
}

.color-violet-600 {
  color: vars.$color-violet-600;
}

.color-blue-sky-500 {
  color: vars.$color-blue-sky-500;
}

.color-dias-con-feriado {
  background: vars.$color-blue-200;
  border-radius: 100%;
}

.overdue-tasks {
  .mat-calendar-body-cell-content {
    border: vars.$color-orange-500 3px solid !important;
  }
}

.pending-tasks {
  .mat-calendar-body-cell-content {
    border: vars.$color-yellow-500 3px solid !important;
  }
}

.completed-tasks {
  .mat-calendar-body-cell-content {
    border: vars.$color-green-500 3px solid !important;
  }
}

.white-stroke {
  circle {
    stroke: vars.$color-white !important;
  }
}

// Virtual scroll - look de tabla con hover
.virtual-row:hover,
tr.mat-row:hover {
  background: vars.$color-blue-100;
}

.gray-hover:hover {
  background-color: vars.$color-gray-100;
}

// Sombra suave del sistema
.soft-shadow {
  box-shadow: vars.$shadow-standard !important;
}

// mat-icons with svgIcon property
mat-icon {
  &.mat-white {
    color: vars.$color-white;

    svg {
      fill: vars.$color-white;

      path {
        fill: vars.$color-white;
      }
    }
  }

  &.mat-accent {
    color: vars.$color-gray-600;

    svg {
      fill: vars.$color-gray-600;

      path {
        fill: vars.$color-gray-600;
      }
    }
  }
}
