@use 'vars';

.dashboard-grid-container {
  padding-bottom: 20px;

  &-block {
    display: table;
    height: auto;
    width: 100%;
  }

  .mat-grid-list {
    display: flex;
    flex: 1;
  }

  .mat-grid-tile {
    background-color: vars.$color-white;
    border-radius: vars.$border-radius-standard;
    box-shadow: vars.$shadow-standard;
  }
}

.ngx-charts {
  .treemap-label {
    font-size: 0.625rem;
  }
}
