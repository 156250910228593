@use 'vars';

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-self-end {
  justify-self: end;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-grow-2 {
  flex-grow: 2 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-left: 0 !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 2rem;
}

.d-none {
  display: none !important;
}

.w-100 {
  width: 100% !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.center-absolute {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  text-align: center;
}

.r-0 {
  right: 0;
}

.vh-100 {
  height: 100vh;
}

.bold {
  font-weight: bold;
}

.thin {
  font-weight: 300;
}

.regular {
  font-weight: initial;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;

  &.mdc-tooltip > div {
    text-align: left !important;
  }
}

.text-right {
  text-align: right !important;
}

.text-end {
  text-align: end !important;
}

.text-upper {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lower {
  text-transform: lowercase;
}

.text-wrap {
  text-wrap: wrap !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-prewrap {
  white-space: pre-wrap !important;
}

.text-sm {
  font-size: 0.75rem;
}

.break-all {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.hide-scrollbar {
  scrollbar-width: none;
}

.hide-overflow {
  overflow: hidden !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pr-6 {
  padding-right: 1.5rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-015 {
  padding-right: 0.15rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.mt-n1 {
  margin-top: -0.25rem;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mtb-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-6 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}

.py-3 {
  padding-bottom: 0.75rem !important;
  padding-top: 0.75rem !important;
}

.py-2 {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}

.py-1 {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}

.h-100 {
  height: 100% !important;
}

.w-0 {
  width: 0;
}

.w-05 {
  max-width: 05%;
  width: 05%;
}

.w-10 {
  max-width: 10%;
  width: 10%;
}

.w-15 {
  max-width: 15%;
  width: 15%;
}

.w-20 {
  max-width: 20%;
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  max-width: 40%;
  width: 40%;
}

.w-45 {
  max-width: 45%;
  width: 45%;
}

.w-60 {
  max-width: 60%;
  width: 60%;
}

.w-65 {
  max-width: 65%;
  width: 65%;
}

.w-80 {
  max-width: 80%;
  min-width: 40%;
  width: 80%;
}

.w-85 {
  max-width: 85%;
  width: 85%;
}

.w-33 {
  max-width: 33%; // Sin el max-width no se alinea
  width: 33%;
}

.mw-20 {
  min-width: 20%;
}

.mb-2 {
  margin-bottom: 0.5em !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}
.mb-6,
.my-6 {
  margin-bottom: 1.5rem !important;
}

.m-0 {
  margin: 0;

  p {
    margin: 0;
  }
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-0 {
  margin-left: 0;
}

.ml-15 {
  margin-left: 5px !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.d-inline-flex {
  display: inline-flex;
}

.d-flex-equal {
  display: flex;
  flex: 1 1 0;
}

.d-flex-auto {
  display: flex;
  flex: 1 1 auto;
}

.d-flex-full {
  display: flex;
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: flex-center !important;
}

.vertical-middle {
  vertical-align: middle;
}

.vertical-bottom {
  vertical-align: bottom;
}

.float-right {
  float: right;
}

.overflow-auto-wh {
  overflow: auto;
  overflow-x: hidden;
}

.overflow-auto {
  overflow: auto;
}

.z-0 {
  z-index: 0 !important;
}

.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-33 {
  width: 33%;
}

.w-25 {
  width: 25%;
}

.w-23 {
  width: 23% !important;
}

.w-30 {
  width: 30%;
}

.w-10 {
  width: 10% !important;
}

.w-100 {
  width: 100%;
}

.pointer-color:hover {
  background-color: vars.$color-blue-100;
}

.pointer,
.pointer-color {
  cursor: pointer !important;
}

.pointer,
.pointer-color > * {
  cursor: pointer !important;
}

.img-fluid {
  height: auto;
  max-width: 100%;
}

.radius-0 {
  border-radius: 0px !important;
}

.no-shadow {
  box-shadow: none !important;
}

.shadow-standard {
  box-shadow: vars.$shadow-standard;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.75rem !important;
}

.mt--3 {
  margin-top: -0.75rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1rem !important;
}

.mt-12,
.my-12 {
  margin-top: 3em !important;
}

.mb-12,
.my-12 {
  margin-bottom: 3rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 2rem !important;
}

.flex-basis-50 {
  flex-basis: 50% !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-italic {
  font-style: italic;
}

.gradient-container {
  flex-shrink: 0;
}

.break-line {
  white-space: pre-line;
}

.elypsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hidden {
  visibility: hidden;
}

.cursor-auto {
  cursor: auto;
}

@media screen and (min-width: (vars.$sm + 1)) {
  .d-md-none {
    display: none !important;
  }
  .d-md-block {
    display: block !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
}

@media screen and (min-width: (vars.$md + 1)) {
  .w-lg-33 {
    width: 33% !important;
  }

  .hidden-lg {
    display: none !important;
  }

  .hide-lg {
    display: none !important;
  }

  .ml-lg-3 {
    margin-left: 0.75rem !important;
  }

  .px-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-lg-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
}

// medium
@media screen and (max-width: vars.$md) {
  .hidden-sm {
    display: none !important;
  }

  .ws-100 {
    width: 100%;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }

  .hide-md {
    display: none !important;
  }

  .w-35 {
    width: 100% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-33 {
    width: 33% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 1.5rem !important;
  }
  .w-100-md {
    width: 100% !important;
    max-width: 100% !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mt-md-8 {
    margin-top: 2rem !important;
  }
}

@media screen and (min-width: (vars.$md + 1)) {
  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  // Displays

  .d-lg-block {
    display: block !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }
}

@media screen and (min-width: (vars.$lg + 1)) {
  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
}

@media screen and (max-width: vars.$sm) {
  .d-sm-block {
    display: block !important;
  }

  .mt-sm-2 {
    margin-top: 1rem !important;
  }

  .hidden-only-sm {
    display: none !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-33 {
    width: 33% !important;
  }

  .w-sm-50 {
    max-width: 50% !important;
    width: 50% !important;
  }

  .pr-sm-0 {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: (vars.$xl + 1)) {
  .d-xl-block {
    display: block !important;
  }
}
