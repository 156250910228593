@use 'core';
@use 'src/styles/vars';

@mixin _unelevated-button-variant($theme, $palette) {
  --mdc-filled-button-label-text-color: #{core.get-theme-color($theme, $palette, default-contrast)};
  --mat-filled-button-state-layer-color: #{core.get-theme-color($theme, $palette, default-contrast)};
  --mat-mdc-button-persistent-ripple-color: #{core.get-theme-color($theme, $palette, default-contrast)};
  --mat-mdc-button-ripple-color: #{rgba(core.get-theme-color($theme, $palette, default-contrast), 0.1)};
}

@mixin _raised-button-variant($theme, $palette) {
  --mdc-protected-button-label-text-color: #{core.get-theme-color($theme, $palette, default-contrast)};
  --mat-protected-button-state-layer-color: #{core.get-theme-color($theme, $palette, default-contrast)};
  --mat-mdc-button-persistent-ripple-color: #{core.get-theme-color($theme, $palette, default-contrast)};
  --mat-mdc-button-ripple-color: #{rgba(core.get-theme-color($theme, $palette, default-contrast), 0.1)};
}

@mixin _fab-color($theme, $palette) {
  --mdc-fab-icon-color: #{core.get-theme-color($theme, $palette, default-contrast)};
  --mat-mdc-fab-color: #{core.get-theme-color($theme, $palette, default-contrast)};
  --mat-mdc-button-persistent-ripple-color: #{core.get-theme-color($theme, $palette, default-contrast)};
  --mat-mdc-button-ripple-color: #{rgba(core.get-theme-color($theme, $palette, default-contrast), 0.1)};
}

@mixin _fab-colors($theme) {
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    &.mat-primary {
      @include _fab-color($theme, primary);
    }

    &.mat-accent {
      @include _fab-color($theme, accent);
    }

    &.mat-warn {
      @include _fab-color($theme, warn);
    }
  }
}

@mixin button-theme($theme) {
  .mat-mdc-unelevated-button {
    &.mat-primary {
      @include _unelevated-button-variant($theme, primary);
    }

    &.mat-accent {
      @include _unelevated-button-variant($theme, accent);
    }

    &.mat-warn {
      @include _unelevated-button-variant($theme, warn);
    }

    &.mat-danger {
      background-color: vars.$color-red-500 !important;
      color: vars.$color-white !important;
    }

    &.mat-success {
      background-color: vars.$color-green-500 !important;
      color: vars.$color-white !important;
    }
  }

  .mat-mdc-raised-button {
    --mdc-protected-button-label-text-color: #{vars.$color-gray-700};

    &.mat-primary {
      @include _raised-button-variant($theme, primary);
    }

    &.mat-accent {
      @include _raised-button-variant($theme, accent);
    }

    &.mat-warn {
      @include _raised-button-variant($theme, warn);
    }

    &.mat-danger {
      background-color: vars.$color-red-500 !important;
      color: vars.$color-white !important;
    }

    &.mat-success {
      background-color: vars.$color-green-500 !important;
      color: vars.$color-white !important;
    }
  }

  .mat-mdc-outlined-button,
  .mat-mdc-icon-button {
    &.mat-danger {
      border-color: vars.$color-red-500 !important;
      color: vars.$color-red-500 !important;
      background-color: vars.$color-white !important;
    }
    &.mat-success {
      color: vars.$color-green-500 !important;
    }
  }

  .mat-mdc-icon-button {
    &.mat-primary {
      background-color: vars.$color-white !important;
    }
  }

  .mat-success[disabled],
  .mat-danger[disabled],
  .mat-warning[disabled] {
    background-color: vars.$color-gray-400 !important;
    color: vars.$color-gray-600 !important;
  }

  .mat-mdc-button {
    --mdc-typography-button-letter-spacing: normal !important;
  }

  .mdc-button {
    --mdc-typography-button-letter-spacing: normal !important;
  }

  .mat-mdc-unelevated-button > .mat-icon,
  .mat-mdc-raised-button > .mat-icon,
  .mat-mdc-outlined-button > .mat-icon {
    margin: 0 !important;
  }

  @include _fab-colors($theme);
}

button.add-reaction-button {
  min-width: auto;
  border-radius: 1rem;
  padding: 0 8px;
  .mat-icon {
    margin: 0;
  }
}
