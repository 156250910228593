@use 'vars';

.ql-mention-list-container {
  width: 270px;
  max-height: 250px;
  overflow: auto;
  border: 1px solid vars.$color-gray-100;
  border-radius: 0.25rem;
  z-index: 1010;
  background-color: vars.$color-white;
  box-shadow: vars.$shadow-standard;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 1rem;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;
  line-height: 44px;
  font-size: 1rem;
  padding: 0 20px;
  vertical-align: middle;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  background-color: vars.$color-gray-100;
  text-decoration: none;
}

.mention {
  cursor: pointer;
  color: vars.$color-blue-primary;
  height: 1.5rem;
  border-radius: vars.$border-radius-standard;
  background-color: vars.$color-gray-100;
  padding: 5px;
  margin-right: 2px;
  display: inline-flex;
  align-items: center;
  user-select: all;
  * {
    pointer-events: none;
  }
}
